import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./styles/index.scss";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations.js";

import {
  getApplicationConfig,
  getTranslationsOverride,
} from "./components/functions/config.js";
import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const initializeMsal = async (config) => {
  try {
    const msalInstance = new PublicClientApplication({
      auth: {
        clientId: config.msal.clientId,
        authority:
          config.msal.mode === "legacy"
            ? `https://login.microsoftonline.com/${config.msal.authority}`
            : config.msal.authority,
        redirectUri: "/",
        protocolMode:
          config.msal.mode === "legacy" ? "AAD" : config.msal.protocolMode,
        knownAuthorities:
          config.msal.mode === "legacy" ? [] : [config.msal.authority],
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                return;
            }
          },
        },
      },
    });

    return msalInstance;
  } catch (error) {
    console.error("Error initializing MSAL:", error);
    return null;
  }
};

const startApp = async () => {
  const config = await getApplicationConfig();

  if (!config) {
    console.error("No application configuration");
    return;
  }

  document.title =
    (config.settings?.applicationTitlePrefix || "Hej") +
    " " +
    config.customer.companyShort;

  const msalInstance = await initializeMsal(config);

  if (!msalInstance) {
    console.error("MSAL could not be initialized.");
    return;
  }

  try {
    // Initialize i18n
    i18n.use(initReactI18next).init({
      resources: translations,
      lng: config.settings?.i18n?.defaultLanguage || "en",
      fallbackLng: config.settings?.i18n?.fallbackLng || "en",
      interpolation: {
        escapeValue: false,
      },
    });

    try {
      const translationsOverride = await getTranslationsOverride();

      if (!translationsOverride) {
        console.error("Translations override is missing or invalid.");
        return;
      }

      // Loop through the keys in translationsOverride and add resource bundles dynamically
      Object.keys(translationsOverride).forEach((langCode) => {
        const translationData = translationsOverride[langCode]?.translation;
        console.log("Adding translation data for language code:", langCode);

        if (translationData) {
          i18n.addResourceBundle(
            langCode, // Language code (e.g., "en", "sv", etc.)
            "translation",
            translationData, // Corresponding translation data
            true, // Should overwrite existing resources
            true // Should deep merge
          );
        }
      });
    } catch (error) {
      console.error("Error loading translations-override.json:", error);
      throw error;
    }
  } catch (error) {
    console.error("Error initializing i18n:", error);
  }

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
};

startApp();
