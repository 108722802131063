import { useEffect, useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./contexts/AuthContext.js";
import { ApplicationContext } from "./contexts/ApplicationContext.js";
import { useImagePreloader } from "./functions/imagePreloader.js";

import styles from "../styles/Topper.module.scss";
import genericUserPhoto from "../img/generic-user.png";
import crossWhiteIcon from "../img/cross-white.svg";
import infoCircleLightblueIcon from "../img/info-circle-lightblue.svg";
import warningsShieldYellowIcon from "../img/warning-shield-yellow.svg";
import warningShieldRedIcon from "../img/warning-shield-red.svg";
import crossIcon from "../img/cross.svg";

export default function Topper() {
  const { t, i18n } = useTranslation();
  const { user, handleLogout } = useContext(AuthContext);
  const [statuses, setStatuses] = useState([]);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const languageMenuRef = useRef(null);
  const { applicationConfig } = useContext(ApplicationContext);

  useImagePreloader([crossWhiteIcon]);

  const toggleMenu = (e) => {
    if (e) {
      e.stopPropagation();
    }

    setLanguageMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (user.authorized && user.settings) {
      let userSettings = user.settings;

      if (typeof user.settings === "string") {
        try {
          userSettings = JSON.parse(user.settings);
        } catch (error) {
          console.error("Error parsing user settings:", error);
        }
      }

      if (userSettings.preferredLanguage) {
        if (userSettings.preferredLanguage !== i18n.language) {
          i18n.changeLanguage(userSettings.preferredLanguage);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    const fetchStatuses = () => {
      fetch(`${process.env.PUBLIC_URL}/statuses.json`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch statuses.json");
          }
          return response.json();
        })
        .then((data) => {
          setStatuses(data);
        })
        .catch((error) => {
          console.error("Error fetching statuses JSON file:", error);
        });
    };

    fetchStatuses(); // Initial fetch

    const intervalId = setInterval(fetchStatuses, 5 * 60 * 1000); // Fetch every 5 minutes

    return () => clearInterval(intervalId);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  const changeLanguage = async (lng) => {
    setLanguageMenuOpen(false);
    i18n.changeLanguage(lng);

    try {
      const response = await fetch("/api/auth/update-user-settings", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ settings: { preferredLanguage: lng } }),
      });

      if (!response.ok) {
        console.error("Failed to update user settings");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Adding event listener when menu is expanded and removing it when collapsed
  useEffect(() => {
    // Function to handle outside click
    const handleOutsideClick = (event) => {
      if (
        languageMenuOpen &&
        languageMenuRef.current &&
        !languageMenuRef.current.contains(event.target)
      ) {
        // Clicked outside the menu, close menu
        toggleMenu();
      }
    };

    // Function to handle escape key press
    const handleEscapePress = (event) => {
      if (languageMenuOpen && event.key === "Escape") {
        toggleMenu();
      }
    };

    if (languageMenuOpen) {
      // Delay adding event listeners until after the current event loop
      setTimeout(() => {
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("keydown", handleEscapePress);
      }, 0);
    }

    // Clean up the event listener on unmount or when the menu is collapsed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, [languageMenuOpen]);

  const getStatusClassName = (severity) => {
    switch (severity) {
      case "info":
        return styles.info;
      case "warning":
        return styles.warning;
      case "critical":
        return styles.critical;
      default:
        return styles.info;
    }
  };

  const clearStatus = (id) => {
    setStatuses((prev) => prev.filter((status) => status.id !== id));
  };

  return (
    <>
      <div className={styles.component}>
        <div className={styles.leftObjects}>
          <img
            src={"/logo.png"}
            alt={t("Logo")}
            style={{ width: 236, height: "auto", cursor: "pointer" }}
            onClick={refreshPage}
          />
        </div>
        <div className={styles.rightObjects}>
          <div>
            <h1>{user.displayName}</h1>
            <span onClick={handleLogout}>{t("Log out")}</span>
          </div>
          <img
            src={user.photo || genericUserPhoto}
            alt={user.displayName}
            style={{ width: 64, height: 64, opacity: user.photo ? 1 : 0.2 }}
          />

          {applicationConfig.settings?.i18n && (
            <>
              <div
                className={styles.languageSwitcher}
                onClick={() => toggleMenu()}
              >
                <img
                  src={
                    languageMenuOpen
                      ? crossWhiteIcon
                      : `/flags/${i18n.language}.svg`
                  }
                  width={20}
                  alt="Language switcher"
                />
              </div>
              <div
                className={styles.languageMenu}
                style={{ display: languageMenuOpen ? null : "none" }}
                ref={languageMenuRef}
              >
                {applicationConfig.settings.i18n?.languages?.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => changeLanguage(lang.code)}
                  >
                    <img src={`/flags/${lang.code}.svg`} width={20} />
                    {lang.name}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {statuses.length > 0 && (
        <div className={styles.statuses}>
          {statuses.map((status) => (
            <div key={status.id} className={styles.status}>
              <div className={getStatusClassName(status.severity)}>
                <img
                  width={20}
                  src={
                    status.severity === "info"
                      ? infoCircleLightblueIcon
                      : status.severity === "warning"
                      ? warningsShieldYellowIcon
                      : status.severity === "critical"
                      ? warningShieldRedIcon
                      : infoCircleLightblueIcon
                  }
                  alt={status.severity}
                />
                <span>{status.message}</span>
                <button
                  className={styles.clear}
                  onClick={() => clearStatus(status.id)}
                  title={t("Clear status")}
                >
                  <img
                    className={styles.clearIcon}
                    src={crossIcon}
                    width={20}
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
