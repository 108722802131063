import { useEffect } from "react";

/**
 * Preloads a single image and returns a promise
 * @param {string} src - URL of the image to preload
 * @returns {Promise} - Promise that resolves when the image is loaded
 */
export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

/**
 * Preloads an array of images concurrently
 * @param {string[]} images - Array of image URLs to preload
 * @returns {Promise} - Promise that resolves when all images are loaded
 */
export const preloadImages = (images) => {
  const promises = images.map((src) => preloadImage(src));
  return Promise.all(promises);
};

/**
 * Hook to preload images when a component mounts
 * @param {string[]} images - Array of image URLs to preload
 */
export const useImagePreloader = (images) => {
  useEffect(() => {
    preloadImages(images).catch((error) =>
      console.error("Error preloading images:", error)
    );
  }, []);
};
