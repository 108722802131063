const translations = {
  sv: {
    translation: {
      // Swedish translations
      Hello: "Hej",
      Attached: "Bifogad",
      Chat: "Chatta",
      History: "Historik",
      Loading: "Laddar",
      Logo: "Logotyp",
      send: "Skicka",
      close: "Stäng",
      Stop: "Stoppa",
      title: "Titel",
      No: "Nej",
      Name: "Namn",
      Description: "Beskrivning",
      Instructions: "Instruktioner",
      Cancel: "Avbryt",
      Save: "Spara",
      Assistant: "Assistenten",
      Pin: "Fäst",
      Unpin: "Avfäst",
      Collapse: "Kollapsa",
      Expand: "Expandera",
      Editing: "Redigerar",

      "Open menu": "Öppna meny",
      "Attach files": "Bifoga filer",
      "Attach image": "Bifoga bild",
      "Attached image": "Bifogad bild",
      "Attaching file": "Bifogar fil",
      "Embedded content": "Inbäddat innehåll",
      "Generated image": "Genererad bild",
      "Hello icon": "Hej ikon",
      "Info icon": "Info ikon",
      "Copy answer": "Kopiera hela svaret",
      "Copy paragraph": "Kopiera stycket",
      "Log out": "Logga ut",
      "Profile picture": "Profilbild",
      "Delete file": "Radera fil",
      "Delete conversation": "Radera konversation",
      "Start new conversation": "Starta ny konversation",
      "Remove image": "Ta bort bild",
      "Window width": "Fönsterbredd",
      "Policy icon": "Policyikon",
      "I understand": "Jag förstår",
      "Activate History": "Aktivera historik",
      "Deactivate History": "Inaktivera historik",
      "Activate now": "Aktivera nu",
      "Deactivate now": "Inaktivera nu",
      "No thanks": "Nej tack!",
      "Unnamed chat": "Namnlös chatt",
      "Generating response": "Genererar svar",
      "Login with Microsoft SSO": "Logga in med Microsoft SSO",
      "Clear status": "Rensa status",

      "Are you sure you want to delete this assistant?":
        "Är du säker på att du vill radera denna assistent?",
      "Select assistant": "Välj assistent",
      "Edit assistant": "Redigera assistent",
      "Delete assistant": "Radera assistent",
      "Expand assistant": "Expandera assistent",
      "Collapse assistant": "Kollapsa assistent",
      "Clear selected assistant": "Rensa vald assistent",
      "Pin assistant": "Fäst assistent",
      "Unpin assistant": "Avfäst assistent",
      "No description": "Ingen beskrivning",
      "Create new assistant": "Skapa ny assistent",
      "Enter your assistant's name": "Ange din assistents namn",
      "Enter your assistant's description": "Ange din assistents beskrivning",
      "Enter your assistant's instructions":
        "Ange din assistents instruktioner",
      "Saving...": "Sparar...",
      "Loading assistants": "Laddar assistenter",

      "When you activate the history feature, the system will start storing your conversations.":
        "När du aktiverar historikfunktionen börjar systemet att lagra dina konversationer.",
      "We encrypt all stored conversation history.":
        "Vi krypterar all lagrad konversationshistorik.",
      "A conversation is saved for 90 days.":
        "En konversation sparas i 90 dagar.",
      "If you decide you no longer want your conversations to be stored.":
        "Om du bestämmer dig för att du inte längre vill att dina konversationer ska sparas.",

      "By disabling history, the system will stop storing your conversations.":
        "Genom att inaktivera historiken slutar systemet att lagra dina konversationer.",
      "You can activate the history feature again.":
        "Du kan givetvis aktivera historikfunktionen igen.",
      "Are you sure you want to disable history and delete all your conversation history?":
        "Är du säker på att du vill inaktivera historiken och radera all din konversationshistorik?",

      "When you activate the history function, the system begins to store your conversations in the service, so that you can resume previous conversations or retrieve previously created material.":
        "När du aktiverar historikfunktionen börjar systemet att lagra dina konversationer i tjänsten, så att du kan återuppta tidigare konversationer eller hämta tidigare skapat material.",
      "We of course encrypt all stored conversation history, so that only you have access to it.":
        "Vi krypterar självklart all lagrad konversationshistorik, så att endast du har tillgång till den.",
      "A conversation is saved for 90 days after you last wrote in it, and you can delete a specific conversation whenever you wish.":
        "En konversation sparas i 90 dagar efter att du senast skrev i den, och du kan radera en specifik konversation när du själv önskar.",
      "If you decide that you no longer want your conversations to be saved, you can deactivate the history function at any time, and then your entire conversation history will be erased.":
        "Om du bestämmer dig för att du inte längre vill att dina konversationer ska sparas, kan du när som helst inaktivera historikfunktionen, och då rensas hela din konversationshistorik.",

      "An unexpected error occurred, please try again later.":
        "Ett oväntat fel uppstod. Försök igen senare.",

      "You can deactivate the conversation history by clicking 'Deactivate history' at the bottom of this menu.":
        'Du kan inaktivera konversationshistoriken genom att klicka på "Inaktivera historik" längst ner i denna meny.',
    },
  },
  no: {
    translation: {
      // Norwegian translations
      Hello: "Hei",
      Attached: "Vedlagt",
      Chat: "Chat",
      History: "Historikk",
      Loading: "Laster",
      Logo: "Logo",
      send: "Send",
      close: "Lukk",
      Stop: "Stopp",
      title: "Tittel",
      No: "Nei",
      Name: "Navn",
      Description: "Beskrivelse",
      Instructions: "Instruksjoner",
      Cancel: "Avbryt",
      Save: "Lagre",
      Assistant: "Assistent",
      Pin: "Fest",
      Unpin: "Løsne",
      Collapse: "Kollaps",
      Expand: "Utvid",
      Editing: "Redigerer",
      "Open menu": "Åpne meny",
      "Attach files": "Legg ved filer",
      "Attach image": "Legg ved bilde",
      "Attached image": "Vedlagt bilde",
      "Attaching file": "Legger ved fil",
      "Embedded content": "Innebygd innhold",
      "Generated image": "Generert bilde",
      "Hello icon": "Hei ikon",
      "Info icon": "Informasjonsikon",
      "Copy answer": "Kopier svar",
      "Copy paragraph": "Kopier avsnitt",
      "Log out": "Logg ut",
      "Profile picture": "Profilbilde",
      "Delete file": "Slett fil",
      "Delete conversation": "Slett samtale",
      "Start new conversation": "Start ny samtale",
      "Remove image": "Fjern bilde",
      "Window width": "Vindusbredde",
      "Policy icon": "Policy-ikon",
      "I understand": "Jeg forstår",
      "Activate History": "Aktiver historikk",
      "Deactivate History": "Deaktiver historikk",
      "Activate now": "Aktiver nå",
      "Deactivate now": "Deaktiver nå",
      "No thanks": "Nei takk!",
      "Unnamed chat": "Uten navn",
      "Generating response": "Genererer svar",
      "Login with Microsoft SSO": "Logg inn med Microsoft SSO",
      "Clear status": "Fjern status",

      "Are you sure you want to delete this assistant?":
        "Er du sikker på at du vil slette denne assistenten?",
      "Select assistant": "Velg assistent",
      "Edit assistant": "Rediger assistent",
      "Delete assistant": "Slett assistent",
      "Expand assistant": "Utvid assistent",
      "Collapse assistant": "Kollaps assistent",
      "Clear selected assistant": "Fjern valgt assistent",
      "Pin assistant": "Fest assistent",
      "Unpin assistant": "Løsne assistent",
      "No description": "Ingen beskrivelse",
      "Create new assistant": "Opprett ny assistent",
      "Enter your assistant's name": "Skriv inn assistentens navn",
      "Enter your assistant's description":
        "Skriv inn assistentens beskrivelse",
      "Enter your assistant's instructions":
        "Skriv inn assistentens instruksjoner",
      "Saving...": "Lagrer...",
      "Loading assistants": "Laster assistenter",

      "When you activate the history feature, the system will start storing your conversations.":
        "Når du aktiverer historikkfunksjonen, vil systemet begynne å lagre samtalene dine.",
      "We encrypt all stored conversation history.":
        "Vi krypterer all lagret samtalehistorikk.",
      "A conversation is saved for 90 days.": "En samtale lagres i 90 dager.",
      "If you decide you no longer want your conversations to be stored.":
        "Hvis du bestemmer deg for at du ikke lenger vil at samtalene dine skal lagres.",

      "By disabling history, the system will stop storing your conversations.":
        "Ved å deaktivere historikk, vil systemet slutte å lagre samtalene dine.",
      "You can activate the history feature again.":
        "Du kan aktivere historikkfunksjonen igjen.",
      "Are you sure you want to disable history and delete all your conversation history?":
        "Er du sikker på at du vil deaktivere historikk og slette all samtalehistorikk?",

      "When you activate the history function, the system begins to store your conversations in the service, so that you can resume previous conversations or retrieve previously created material.":
        "Når du aktiverer historikkfunksjonen, begynner systemet å lagre samtalene dine i tjenesten, slik at du kan gjenoppta tidligere samtaler eller hente tidligere opprettet materiale.",
      "We of course encrypt all stored conversation history, so that only you have access to it.":
        "Vi krypterer selvfølgelig all lagret samtalehistorikk, slik at bare du har tilgang til den.",
      "A conversation is saved for 90 days after you last wrote in it, and you can delete a specific conversation whenever you wish.":
        "En samtale lagres i 90 dager etter at du sist skrev i den, og du kan slette en spesifikk samtale når som helst.",
      "If you decide that you no longer want your conversations to be saved, you can deactivate the history function at any time, and then your entire conversation history will be erased.":
        "Hvis du bestemmer deg for at du ikke lenger vil at samtalene dine skal lagres, kan du deaktivere historikkfunksjonen når som helst, og da vil hele samtalehistorikken din bli slettet.",

      "An unexpected error occurred, please try again later.":
        "En uventet feil oppstod, vennligst prøv igjen senere.",

      "You can deactivate the conversation history by clicking 'Deactivate history' at the bottom of this menu.":
        "Du kan deaktivere samtalehistorikken ved å klikke på 'Deaktiver historikk' nederst i denne menyen.",
    },
  },
  fi: {
    translation: {
      // Finnish translations
      Hello: "Hei",
      Attached: "Liitetty",
      Chat: "Keskustelu",
      History: "Historia",
      Loading: "Ladataan",
      Logo: "Logo",
      send: "Lähetä",
      close: "Sulje",
      Stop: "Pysäytä",
      title: "Otsikko",
      No: "Ei",
      Name: "Nimi",
      Description: "Kuvaus",
      Instructions: "Ohjeet",
      Cancel: "Peruuta",
      Save: "Tallenna",
      Assistant: "Avustaja",
      Pin: "Kiinnitä",
      Unpin: "Irrota",
      Collapse: "Pienennä",
      Expand: "Laajenna",
      Editing: "Muokataan",
      "Open menu": "Avaa valikko",
      "Attach files": "Liitä tiedostoja",
      "Attach image": "Liitä kuva",
      "Attached image": "Liitetty kuva",
      "Attaching file": "Liitetään tiedostoa",
      "Embedded content": "Upotettu sisältö",
      "Generated image": "Luotu kuva",
      "Hello icon": "Hei-kuvake",
      "Info icon": "Info-kuvake",
      "Copy answer": "Kopioi vastaus",
      "Copy paragraph": "Kopioi kappale",
      "Log out": "Kirjaudu ulos",
      "Profile picture": "Profiilikuva",
      "Delete file": "Poista tiedosto",
      "Delete conversation": "Poista keskustelu",
      "Start new conversation": "Aloita uusi keskustelu",
      "Remove image": "Poista kuva",
      "Window width": "Ikkunan leveys",
      "Policy icon": "Politiikkakuvake",
      "I understand": "Ymmärrän",
      "Activate History": "Aktivoi historia",
      "Deactivate History": "Poista historia käytöstä",
      "Activate now": "Aktivoi nyt",
      "Deactivate now": "Poista käytöstä nyt",
      "No thanks": "Ei kiitos",
      "Unnamed chat": "Nimetön keskustelu",
      "Generating response": "Luodaan vastausta",
      "Login with Microsoft SSO": "Kirjaudu Microsoft SSO:lla",
      "Clear status": "Tyhjennä tila",

      "Are you sure you want to delete this assistant?":
        "Oletko varma, että haluat poistaa tämän avustajan?",
      "Select assistant": "Valitse avustaja",
      "Edit assistant": "Muokkaa avustajaa",
      "Delete assistant": "Poista avustaja",
      "Expand assistant": "Laajenna avustaja",
      "Collapse assistant": "Pienennä avustaja",
      "Clear selected assistant": "Tyhjennä valittu avustaja",
      "Pin assistant": "Kiinnitä avustaja",
      "Unpin assistant": "Irrota avustaja",
      "No description": "Ei kuvausta",
      "Create new assistant": "Luo uusi avustaja",
      "Enter your assistant's name": "Anna avustajasi nimi",
      "Enter your assistant's description": "Anna avustajasi kuvaus",
      "Enter your assistant's instructions": "Anna avustajasi ohjeet",
      "Saving...": "Tallennetaan...",
      "Loading assistants": "Ladataan avustajia",

      "When you activate the history feature, the system will start storing your conversations.":
        "Kun aktivoit historian, järjestelmä alkaa tallentaa keskustelujasi.",
      "We encrypt all stored conversation history.":
        "Salaamme kaikki tallennetut keskusteluhistoriat.",
      "A conversation is saved for 90 days.":
        "Keskustelu tallennetaan 90 päiväksi.",
      "If you decide you no longer want your conversations to be stored.":
        "Jos päätät, ettet enää halua keskustelujesi tallentuvan.",

      "By disabling history, the system will stop storing your conversations.":
        "Poistamalla historian käytöstä järjestelmä lopettaa keskustelujesi tallentamisen.",
      "You can activate the history feature again.":
        "Voit aktivoida historian uudelleen.",
      "Are you sure you want to disable history and delete all your conversation history?":
        "Oletko varma, että haluat poistaa historian käytöstä ja poistaa kaikki keskusteluhistoriasi?",

      "When you activate the history function, the system begins to store your conversations in the service, so that you can resume previous conversations or retrieve previously created material.":
        "Kun aktivoit historian, järjestelmä alkaa tallentaa keskustelujasi palveluun, jotta voit jatkaa aiempia keskusteluja tai hakea aikaisemmin luotua materiaalia.",
      "We of course encrypt all stored conversation history, so that only you have access to it.":
        "Salaamme tietysti kaikki tallennetut keskusteluhistoriat, jotta vain sinulla on pääsy niihin.",
      "A conversation is saved for 90 days after you last wrote in it, and you can delete a specific conversation whenever you wish.":
        "Keskustelu tallennetaan 90 päiväksi viimeisestä kirjoituskerrasta, ja voit poistaa tietyn keskustelun milloin tahansa.",
      "If you decide that you no longer want your conversations to be saved, you can deactivate the history function at any time, and then your entire conversation history will be erased.":
        "Jos päätät, ettet enää halua keskustelujesi tallentuvan, voit poistaa historian käytöstä milloin tahansa, jolloin koko keskusteluhistoriasi poistetaan.",

      "An unexpected error occurred, please try again later.":
        "Odottamaton virhe tapahtui, yritä myöhemmin uudelleen.",

      "You can deactivate the conversation history by clicking 'Deactivate history' at the bottom of this menu.":
        'Voit poistaa keskusteluhistorian käytöstä napsauttamalla "Poista historia käytöstä" tämän valikon alareunasta.',
    },
  },
};

export default translations;
