import { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./contexts/AuthContext.js";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";
import { useImagePreloader } from "./functions/imagePreloader.js";

import styles from "../styles/ChatGptMenu.module.scss";
import dropdownArrowIcon from "../img/dropdown-arrow.svg";
import deleteIcon from "../img/trash.svg";
import pinIcon from "../img/pin.svg";
import pinnedIcon from "../img/pinned.svg";
import clearIcon from "../img/cross-white.svg";
import editIcon from "../img/edit-gpts.svg";

const ChatGptMenu = ({ setChat, selectedGpt, setSelectedGpt }) => {
  const { t } = useTranslation();

  // Contexts
  const { user, updateUserSettings } = useContext(AuthContext);
  const { addMessageBanner } = useContext(MessageBannerContext);

  // Menu states
  const [gpts, setGpts] = useState([]);
  const [pinnedGpts, setPinnedGpts] = useState([]);
  const [selectMenuOpen, setSelectMenuOpen] = useState(false);
  const [createMenuOpen, setCreateMenuOpen] = useState(false);
  const [editMenuOpen, setEditMenuOpen] = useState(false);

  // Creation states
  const [newGptName, setNewGptName] = useState("");
  const [newGptInstructions, setNewGptInstructions] = useState("");

  // Editing states
  const [editingGptId, setEditingGptId] = useState("");
  const [editGptName, setEditGptName] = useState("");
  const [editGptInstructions, setEditGptInstructions] = useState("");
  const [capturedGptName, setCapturedGptName] = useState("");

  // Other states
  const [isLoading, setIsLoading] = useState(false);

  const menuRef = useRef(null);

  useImagePreloader([
    dropdownArrowIcon,
    deleteIcon,
    pinIcon,
    pinnedIcon,
    clearIcon,
    editIcon,
  ]);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setSelectMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Get pinned GPTs from user settings
  useEffect(() => {
    if (user.settings && user.settings.pinnedGpts) {
      setPinnedGpts(user.settings.pinnedGpts || []);
    }
  }, [user.settings]);

  // Fetch GPT list when user is authorized
  useEffect(() => {
    const getGptList = async () => {
      try {
        setIsLoading(true);
        const response = await fetch("/api/gpts/list", {
          method: "GET",
        });

        if (response.ok) {
          const responseBody = await response.json();
          setGpts(responseBody);
        } else {
          const responseBody = await response.json();
          addMessageBanner({
            position: "topMiddle",
            type: "failure",
            text: responseBody.message || "Failed to fetch assistants",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: "Failed to fetch assistants",
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (user.authorized && user.hasAccessToGpts && selectMenuOpen) {
      getGptList();
    }
  }, [user.hasAccessToGpts, selectMenuOpen]);

  const toggleSelectMenu = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setSelectMenuOpen((prev) => !prev);
  };

  const handleSelectGpt = async (gptId) => {
    setChat((prevChat) => {
      return {
        ...prevChat,
        gpt: gptId,
      };
    });
    setSelectedGpt(gptId);
    setSelectMenuOpen(false);
  };

  const handleDeleteGpt = async (e, gptId) => {
    e.stopPropagation(); // Prevent menu from selecting this item

    if (!window.confirm(t("Are you sure you want to delete this assistant?"))) {
      return;
    }

    try {
      const response = await fetch(`/api/gpts/delete/${gptId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        // Update the local state to remove the deleted GPT
        setGpts((prevGpts) => prevGpts.filter((gpt) => gpt.uuid !== gptId));

        // If the deleted GPT was the selected one, reset selection
        if (selectedGpt === gptId) {
          setSelectedGpt("");
        }

        // Remove from pinned GPTs if it was pinned
        if (pinnedGpts.includes(gptId)) {
          const updatedPinnedGpts = pinnedGpts.filter((id) => id !== gptId);
          setPinnedGpts(updatedPinnedGpts);
          updatePinnedGptsSettings(updatedPinnedGpts);
        }

        const responseBody = await response.json();
        addMessageBanner({
          position: "topMiddle",
          type: "success",
          text: responseBody.message,
        });
      } else {
        const responseBody = await response.json();
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: responseBody.message || "Failed to delete assistant",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Failed to delete assistant",
      });
    }
  };

  const handleEditGpt = async (gptId) => {
    try {
      const response = await fetch(`/api/gpts/create-or-edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: gptId,
          name: editGptName,
          settings: { instructions: editGptInstructions },
        }),
      });

      if (response.ok) {
        const editedGpt = await response.json();

        setGpts((prevGpts) =>
          prevGpts.map((gpt) => {
            if (gpt.uuid === gptId) {
              return {
                ...gpt,
                name: editGptName,
                settings: JSON.stringify(editedGpt.settings),
              };
            }
            return gpt;
          })
        );

        // Reset form and close
        setCapturedGptName("");
        setEditingGptId("");
        setEditGptName("");
        setEditGptInstructions("");
        setEditMenuOpen(false);

        // Select the new GPT
        handleSelectGpt(editedGpt.uuid);

        addMessageBanner({
          position: "topMiddle",
          type: "success",
          text: "Assistant edited successfully",
        });
      } else {
        const responseBody = await response.json();
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: responseBody.message || "Failed to edit assistant",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Failed to edit assistant",
      });
    }
  };

  const handleTogglePinGpt = async (e, gptId) => {
    e.stopPropagation();

    let updatedPinnedGpts;
    if (pinnedGpts.includes(gptId)) {
      // Unpin if already pinned
      updatedPinnedGpts = pinnedGpts.filter((id) => id !== gptId);
    } else {
      // Pin if not already pinned
      updatedPinnedGpts = [...pinnedGpts, gptId];
    }

    setPinnedGpts(updatedPinnedGpts);
    updatePinnedGptsSettings(updatedPinnedGpts);
  };

  const updatePinnedGptsSettings = async (updatedPinnedGpts) => {
    try {
      const response = await fetch("/api/auth/update-user-settings", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          settings: {
            pinnedGpts: updatedPinnedGpts,
          },
        }),
      });

      if (response.ok) {
        // Update the context
        if (updateUserSettings) {
          updateUserSettings({
            ...user.settings,
            pinnedGpts: updatedPinnedGpts,
          });
        }
      } else {
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: "Failed to update pinned assistants",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Failed to update pinned assistants",
      });
    }
  };

  const handleCreateGpt = async () => {
    try {
      setIsLoading(true);
      const response = await fetch("/api/gpts/create-or-edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: newGptName,
          settings: { instructions: newGptInstructions },
        }),
      });

      if (response.ok) {
        const newGpt = await response.json();

        // Add the new GPT to the list
        setGpts((prevGpts) => [
          {
            uuid: newGpt.uuid,
            name: newGpt.name,
            settings: JSON.stringify(newGpt.settings),
          },
          ...prevGpts,
        ]);

        // Reset form and close create menu
        setNewGptName("");
        setNewGptInstructions("");
        setCreateMenuOpen(false);

        // Select the new GPT
        handleSelectGpt(newGpt.uuid);

        addMessageBanner({
          position: "topMiddle",
          type: "success",
          text: "Assistant created successfully",
        });
      } else {
        const responseBody = await response.json();
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: responseBody.message || "Failed to create assistant",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Failed to create assistant",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Find the name of the selected GPT
  const selectedGptName = selectedGpt
    ? gpts.find((gpt) => gpt.uuid === selectedGpt)?.name || ""
    : t("Select assistant");

  // Sort GPTs with pinned ones at the top
  const sortedGpts = [...gpts].sort((a, b) => {
    const isPinnedA = pinnedGpts.includes(a.uuid);
    const isPinnedB = pinnedGpts.includes(b.uuid);

    if (isPinnedA && !isPinnedB) return -1;
    if (!isPinnedA && isPinnedB) return 1;

    // If both are pinned, sort by pin order (assuming pinnedGpts is in order of pinning)
    if (isPinnedA && isPinnedB) {
      // Get the index of each GPT in the pinnedGpts array
      const indexA = pinnedGpts.indexOf(a.uuid);
      const indexB = pinnedGpts.indexOf(b.uuid);

      // Sort by the reverse order (most recently pinned at the top)
      return indexB - indexA;
    }

    return 0;
  });

  return (
    <div className={styles.component} ref={menuRef}>
      {user.authorized && (
        <>
          <div
            className={
              `${styles.menuButton}` + (selectMenuOpen ? ` ${styles.open}` : "")
            }
            style={{ opacity: selectedGpt || selectMenuOpen ? 1 : null }}
            onClick={toggleSelectMenu}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              {selectedGptName}
              <img
                src={dropdownArrowIcon}
                width={13}
                alt={t("Select assistant")}
                className={selectMenuOpen ? styles.arrowUp : ""}
              />
            </div>
            {selectedGpt && (
              <div className={styles.clearButton}>
                <img
                  src={clearIcon}
                  width={16}
                  alt={t("Clear selected assistant")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedGpt("");
                    setChat((prevChat) => {
                      return {
                        ...prevChat,
                        gpt: "",
                      };
                    });
                  }}
                />
              </div>
            )}
          </div>
          {selectMenuOpen && !createMenuOpen && (
            <>
              {isLoading && !sortedGpts.length && (
                <div className={styles.loadingList}>
                  <p>{t("Loading assistants") + "..."}</p>
                </div>
              )}
              {sortedGpts.length > 0 && (
                <div className={styles.menu}>
                  {sortedGpts.map((gpt) => (
                    <div key={gpt.uuid} className={styles.gptContainer}>
                      <div
                        className={`${styles.content} ${
                          selectedGpt === gpt.uuid ? styles.selected : ""
                        }`}
                        onClick={() => handleSelectGpt(gpt.uuid)}
                      >
                        <div className={styles.gptNameContainer}>
                          <button
                            className={styles.actionButton}
                            onClick={(e) => handleTogglePinGpt(e, gpt.uuid)}
                            title={
                              pinnedGpts.includes(gpt.uuid)
                                ? t("Unpin assistant")
                                : t("Pin assistant")
                            }
                          >
                            <img
                              src={
                                pinnedGpts.includes(gpt.uuid)
                                  ? pinnedIcon
                                  : pinIcon
                              }
                              width={16}
                              alt={
                                pinnedGpts.includes(gpt.uuid)
                                  ? t("Unpin")
                                  : t("Pin")
                              }
                            />
                          </button>
                          <span className={styles.gptName}>{gpt.name}</span>
                        </div>
                        <div>
                          <button
                            className={styles.actionButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCapturedGptName(gpt.name);
                              setEditingGptId(gpt.uuid);
                              setEditGptName(gpt.name);
                              setEditGptInstructions(
                                JSON.parse(gpt.settings).instructions
                              );
                              setEditMenuOpen(true);
                              window.scrollTo({
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                              });
                            }}
                            title={t("Edit assistant")}
                          >
                            <img src={editIcon} width={16} alt={t("Edit")} />
                          </button>
                          <button
                            className={styles.actionButton}
                            onClick={(e) => handleDeleteGpt(e, gpt.uuid)}
                            title={t("Delete assistant")}
                          >
                            <img
                              src={deleteIcon}
                              width={16}
                              alt={t("Delete")}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {selectMenuOpen && (createMenuOpen || editMenuOpen) ? (
            <div className={styles.last}>
              <div className={styles.createMenu}>
                <h3>
                  {createMenuOpen
                    ? t("Create new assistant")
                    : t("Editing") + " " + capturedGptName}
                </h3>
                <div className={styles.form}>
                  <p>{t("Name")}:</p>
                  <input
                    type="text"
                    className={styles.input}
                    value={createMenuOpen ? newGptName : editGptName}
                    onChange={
                      createMenuOpen
                        ? (e) => setNewGptName(e.target.value)
                        : (e) => setEditGptName(e.target.value)
                    }
                    placeholder={
                      createMenuOpen && t("Enter your assistant's name")
                    }
                  />
                  <p>{t("Instructions")}:</p>
                  <textarea
                    rows="10"
                    cols="50"
                    className={styles.input}
                    value={
                      createMenuOpen ? newGptInstructions : editGptInstructions
                    }
                    onChange={(e) =>
                      createMenuOpen
                        ? setNewGptInstructions(e.target.value)
                        : setEditGptInstructions(e.target.value)
                    }
                    placeholder={
                      createMenuOpen && t("Enter your assistant's instructions")
                    }
                  />
                  <div className={styles.formButtons}>
                    <button
                      type="button"
                      className={styles.cancelButton}
                      onClick={() =>
                        createMenuOpen
                          ? setCreateMenuOpen(false)
                          : setEditMenuOpen(false)
                      }
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="button"
                      className={styles.saveButton}
                      disabled={isLoading}
                      onClick={() =>
                        createMenuOpen
                          ? handleCreateGpt()
                          : handleEditGpt(editingGptId)
                      }
                    >
                      {isLoading ? t("Saving...") : t("Save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : selectMenuOpen ? (
            <div
              className={styles.last}
              onClick={() => setCreateMenuOpen(true)}
              style={{ cursor: "pointer" }}
            >
              <p className={styles.createNew}>+ {t("Create new assistant")}</p>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ChatGptMenu;
